

.active {
  background: linear-gradient(to right, #ff0101, #fd4d4d);
  border: none;
  border-radius: 6px;
  text-decoration: none;
  cursor: pointer;
  color: white;
  box-shadow: 0px 2px 6px 0px rgba(250, 61, 61, 0.48);
}

.navlink {
  text-decoration: none;
  color: #5d5a68;
  border-radius: 6px;
}

.active_mobile {
  background: linear-gradient(to right, #ff0101, #fd4d4d);
  border: none;
  border-radius: 6px;
  text-decoration: none;
  cursor: pointer;
  color: white;
  box-shadow: 0px 2px 6px 0px rgba(250, 61, 61, 0.48);
  width: 100%;
}

.navlink_mobile {
  text-decoration: none;
  color: #5d5a68;
  border-radius: 6px;
  width: 100%;
}

.link {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  text-decoration: none;
}
